import { onError } from '@apollo/client/link/error'

export const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  console.log('createApolloClient_errorLink_Operation: ', operation)
  console.log('Operation name:', operation.operationName)

  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.error(
        `[GraphQL error]: Operation name: ${operation.operationName}, Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    })
  }

  if (networkError) {
    console.error(`[Network error]: ${networkError}`, `Operation name: ${operation.operationName}`)

    if (networkError.message.includes('ECONNREFUSED')) {
      console.error('Unable to connect to the server. Please try again later.')
    }
  }
})
