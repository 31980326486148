import { ApolloLink } from '@apollo/client'
import { SSRMultipartLink } from '@apollo/experimental-nextjs-app-support/ssr'

import type { ApolloLinkConfig } from '../../types'

import { createAuthLink } from './authLink'
import { errorLink } from './errorLink'
import { createLoggerLink } from './loggerLink'

import { routingLink } from '.'

export function createApolloLinks(config: ApolloLinkConfig) {
  const { isSSR, userId, currentProvider } = config

  const authLink = createAuthLink(userId, currentProvider)
  const loggerLink = createLoggerLink()

  let baseLinks = [loggerLink, errorLink, routingLink]

  if (isSSR) {
    baseLinks = [
      new SSRMultipartLink({
        stripDefer: true
      }),
      ...baseLinks
    ]
  }

  const links = userId ? [authLink, ...baseLinks] : baseLinks

  return ApolloLink.from(links)
}
