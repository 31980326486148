'use client'

import { UserButton } from '@clerk/nextjs'
import type { User } from '@prisma/client'
import { ChevronsUpDown } from 'lucide-react'

import { dark } from '@clerk/themes'
import { useTheme } from 'next-themes'
import { SidebarMenu, SidebarMenuButton, SidebarMenuItem } from './ui/sidebar'

type UserNavProps = {
  user: User | null
}

export function UserNav({ user }: UserNavProps) {
  const { theme } = useTheme()

  if (!user) {
    return null
  }

  return (
    <SidebarMenu>
      <SidebarMenuItem>
        <SidebarMenuButton size="lg">
          {user.avatar && <img src={user.avatar} alt={user.name} className="size-8 rounded-lg" />}
          <div className="grid flex-1 text-left text-sm leading-tight">
            <span className="truncate font-semibold">{user.name}</span>
            <span className="truncate text-xs">{user.email}</span>
          </div>
          <ChevronsUpDown className="ml-auto size-4" />
          <UserButton
            afterSignOutUrl="/sign-in"
            appearance={{
              baseTheme: theme === 'dark' ? dark : undefined,
              elements: {
                avatarBox: 'size-8'
              }
            }} 
            userProfileMode="navigation" 
            userProfileUrl={`/profile/${user?.id}/settings`} 
          />
        </SidebarMenuButton>
      </SidebarMenuItem>
    </SidebarMenu>
  )
}
