'use client'

import { usePathname } from 'next/navigation'
import type { PropsWithChildren } from 'react'

import type { User } from '@prisma/client'

import { Alert } from '~/UI-Temp/src/UI/Alert/Alert'
import { AlertContainer } from '../Alert/AlertContainer'
import { Topbar } from '../Topbar'
import { SidebarInset, SidebarProvider } from '../ui/sidebar'

import { MainSidebar } from './MainSidebar'

type DashboardLayoutClientProps = PropsWithChildren<{
  user: User
  permissions: string[]
  capabilities: string[]
  isSidebarOpen: boolean
}>

export function DashboardLayoutClient({
  children,
  user,
  permissions,
  capabilities,
  isSidebarOpen
}: DashboardLayoutClientProps) {
  const pathname = usePathname()
  
  const showOnboardingAlert = !user.isOnboardingCompleted && !pathname.includes('/onboarding')

  return (
    <SidebarProvider defaultOpen={isSidebarOpen}>
      <MainSidebar user={user} permissions={permissions} capabilities={capabilities} />
      <SidebarInset>
        <Topbar user={user} permissions={permissions} capabilities={capabilities} />
        {showOnboardingAlert && (
          <Alert 
            status="info"
            title="Complete Your Onboarding"
            description="Complete your onboarding to unlock all features including chat, profile viewing, and more."
            href="/onboarding"
            linkText="Complete Now →"
          />
        )}
        <div className="flex flex-1 flex-col">
          <AlertContainer />
          <main className="flex-1">{children}</main>
        </div>
      </SidebarInset>
    </SidebarProvider>
  )
}
