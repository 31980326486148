import type { AllLocales, LocalePrefix } from 'node_modules/next-intl/dist/types/src/shared/types'

import { commonEnv } from '../config/env'
import { allLocales } from '../i18n/config'

import {
  SITE_DESCRIPTION,
  SITE_IMAGE_ALT,
  SITE_IMAGE_URL,
  SITE_LANGUAGE,
  SITE_NAME,
  SITE_TYPE
} from './main'

type SiteConfig = {
  siteUrl: URL
  siteLanguage: string
  siteName: string
  siteDescription: string
  siteType: string
  siteImageUrl: string
  siteImageAlt: string
  openGraph: {
    type: 'website'
    locale: string
    site_name: string
  }
  locales: AllLocales
  defaultLocale: string
  localePrefix: LocalePrefix
}

const localePrefix: LocalePrefix = 'as-needed'

export const SITE_CONFIG: SiteConfig = {
  siteUrl: new URL(commonEnv.FRONTEND_URL),
  siteLanguage: SITE_LANGUAGE,
  siteName: SITE_NAME,
  siteDescription: SITE_DESCRIPTION,
  siteType: SITE_TYPE,
  siteImageUrl: SITE_IMAGE_URL,
  siteImageAlt: SITE_IMAGE_ALT,
  openGraph: {
    type: 'website' as const,
    locale: SITE_LANGUAGE,
    site_name: SITE_NAME
  },
  locales: allLocales,
  defaultLocale: 'en',
  localePrefix
}

export const SITE_SETTINGS = {
  isSearchEnabled: false,
  isMegaMenuEnabled: false,
  isDemoMode: false
}

export const { isDemoMode } = SITE_SETTINGS
