import { z } from 'zod'

import { clientEnvSchema } from './clientEnv.schema'
import { serverEnvSchema } from './serverEnv.schema'

const testEnvClientSchema = clientEnvSchema
const testEnvServerSchema = serverEnvSchema

export const testEnvSchema = z.object({
  ...testEnvClientSchema.shape,
  ...testEnvServerSchema.shape
})

export type TestEnvInput = z.TypeOf<typeof testEnvSchema>
