import type { ClientEnvInput } from './clientEnv.schema'
import { clientEnvSchema, clientEnvVariables } from './clientEnv.schema'
import type { ServerEnvInput } from './serverEnv.schema'
import { serverEnvSchema } from './serverEnv.schema'
import type { TestEnvInput } from './testEnv.schema'
import { testEnvSchema } from './testEnv.schema'

export type EnvCombined = ClientEnvInput & Partial<ServerEnvInput> & Partial<TestEnvInput>

/**
 * Loads and validates environment variables based on the current environment.
 * @returns {EnvCombined} The combined and validated environment variables.
 */
export const loadEnv = (): EnvCombined => {
  if (process.env.NODE_ENV === 'test') {
    return {
      // Server-side variables
      ...serverEnvSchema.parse(process.env),
      // Client-side variables
      ...clientEnvSchema.parse(clientEnvVariables),
      // Test-specific variables
      ...testEnvSchema.parse(process.env)
    }
  }

  if (typeof window === 'undefined') {
    return {
      // Server-side variables
      ...serverEnvSchema.parse(process.env),
      // Client-side variables
      ...clientEnvSchema.parse(clientEnvVariables)
    }
  }

  // Client-side only
  return clientEnvSchema.parse(clientEnvVariables)
}
