import type { InMemoryCacheConfig } from '@apollo/client'
import { InMemoryCache } from '@apollo/client'
import { InMemoryCache as InMemoryCacheSSR } from '@apollo/experimental-nextjs-app-support'

/* import introspectionResult from '~/server/graphql/codegen/__generated__/field-policies'
import type { StrictTypedTypePolicies } from '~/server/graphql/codegen/__generated__/type-policies' */
/* const typePolicies: StrictTypedTypePolicies = { */

const typePolicies: any = {
  // Query: {
  //   fields: {
  //     cart: {
  //       read() {
  //         return cache.readQuery({ query: GET_CART })
  //       }
  //     }
  //   }
  // },
  // Cart: {
  //   fields: {
  //     items: {
  //       merge(existing = [], incoming) {
  //         return [...incoming]
  //       }
  //     }
  //   }
  // }
}

type CacheConstructor = new (
  config?: InMemoryCacheConfig | undefined
) => InMemoryCache | InMemoryCacheSSR

function getApolloCache(CacheConstructor: CacheConstructor) {
  const cache = new CacheConstructor({
    /*     possibleTypes: introspectionResult.possibleTypes, */
    typePolicies
  })

  return cache
}

export const apolloNextSSRCache = getApolloCache(InMemoryCacheSSR)
export const apolloRSCCache = getApolloCache(InMemoryCache)
