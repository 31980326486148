'use client'

import { useEffect, useState } from 'react'

import clsx from 'clsx'
import { motion } from 'framer-motion'
import { Sun, Moon } from 'lucide-react'
import { useTheme } from 'next-themes'

type Theme = 'dark' | 'light'

type ToggleThemeModeProps = {
  className?: string
}

export const ToggleThemeMode = (props: ToggleThemeModeProps) => {
  const { className = '' } = props

  return (
    <div
      {...props}
      className={clsx(
        className,
        'flex size-[44px] items-center justify-center border-none bg-transparent text-text outline-none'
      )}
    >
      <RenderButton />
    </div>
  )
}

function RenderButton() {
  const { setTheme, resolvedTheme } = useTheme()
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
  }, [])

  // Use resolvedTheme for consistent handling
  const isDark = resolvedTheme === 'dark'

  function changeTheme() {
    setTheme(isDark ? 'light' : 'dark')
  }

  if (!mounted) {
    // Prevent mismatched UI during hydration
    return null
  }

  return (
    <motion.button
      suppressHydrationWarning
      type="button"
      className="relative flex size-full items-center justify-center rounded-full bg-background text-text transition-colors hover:bg-muted/80"
      onClick={changeTheme}
      aria-label={`Switch to ${isDark ? 'light' : 'dark'} mode`}
      title={`Switch to ${isDark ? 'light' : 'dark'} mode`}
      whileTap={{ scale: 0.9 }}
    >
      {/* Sun Icon Animation */}
      <motion.div
        initial={false}
        animate={{
          rotate: isDark ? 0 : 180,
          y: isDark ? -20 : 0,
          opacity: isDark ? 0 : 1
        }}
        transition={{ duration: 0.5, ease: 'easeInOut' }}
        className="absolute"
      >
        <Sun className="size-5" />
      </motion.div>

      {/* Moon Icon Animation */}
      <motion.div
        initial={false}
        animate={{
          rotate: isDark ? 0 : -180,
          y: isDark ? 0 : 20,
          opacity: isDark ? 1 : 0
        }}
        transition={{ duration: 0.5, ease: 'easeInOut' }}
        className="absolute"
      >
        <Moon className="size-5" />
      </motion.div>
    </motion.button>
  )
}
