import type { Pathnames } from 'next-intl/navigation'
import type { LocalePrefix } from 'next-intl/routing'

export type Locale = {
  code: string
  localName: string
  name: string
  langDir: 'ltr' | 'rtl'
  dateFormat: string
  hrefLang: string
  enabled: boolean
  default: boolean
}

const locales: Locale[] = [
  {
    code: 'en',
    localName: 'English',
    name: 'English',
    langDir: 'ltr' as const,
    dateFormat: 'MM.DD.YYYY',
    hrefLang: 'en-GB',
    enabled: true,
    default: true
  },
  {
    code: 'sv',
    localName: 'Svenska',
    name: 'Swedish',
    langDir: 'ltr' as const,
    dateFormat: 'YYYY-MM-DD',
    hrefLang: 'sv',
    enabled: false,
    default: false
  }
]

export type AvailableLocale = (typeof locales)[number]

export type LocaleCode = AvailableLocale['code']

export const allLocales: readonly LocaleCode[] = locales.map(locale => {
  return locale.code
})

export const defaultLocale = locales.find(locale => {
  return locale.default
}) as AvailableLocale

// As set of available and enabled locales for the website
// This is used for allowing us to redirect the user to any
// of the available locales that we have enabled on the website
export const availableLocales = locales.filter(locale => {
  return locale.enabled
})

export const availableLocalesMap = Object.fromEntries(
  locales.map(locale => {
    return [locale.code, locale]
  })
) as Record<string, Locale>

export const localePrefix: LocalePrefix = 'always'

export const pathnames = {
  '/': '/',
  '/pathnames': {
    en: '/pathnames',
    sv: '/filvägar'
  }
} satisfies Pathnames<typeof allLocales>
