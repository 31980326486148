import { ApolloLink } from '@apollo/client'

import { isServer } from '~/utils/isServer'

/**
 * Creates and returns an Apollo Link middleware that logs GraphQL operation details.
 * This logger distinguishes between client-side and server-side operations (if applicable),
 * and logs the operation's context, result, and execution time in a visually distinct manner
 * using console styling.
 *
 * Note: The styling applied via console.log is intended for browser consoles that support CSS styling.
 *
 * @returns {ApolloLink} Apollo Link middleware that logs operation details.
 */
export const createLoggerLink = () => {
  return new ApolloLink((operation, forward) => {
    const { variables, getContext } = operation
    const { headers = {} } = getContext()
    const isClient = !isServer

    // Prefix to indicate whether the operation is happening on the client or server side.
    const prefix = isClient ? 'CLIENT SIDE:' : 'SERVER SIDE:'
    // Styling for different parts of the log message.
    const prefixStyle = 'color: #2979ff; font-weight: bold;'
    const nameStyle = 'color: #ffa726;'
    const contextStyle = 'color: #66bb6a;'
    const timeStyle = 'color: #78909c;'

    /**
     * Logs the operation's context, including variables and headers, using styled console messages.
     */
    console.log(
      `%c${prefix} %c${operation.operationName || 'Operation'} %cContext:`,
      prefixStyle,
      nameStyle,
      contextStyle,
      JSON.stringify({ headers, variables }, undefined, 2)
    )

    // Record the start time of the operation to measure execution time.
    const startTime = performance.now()

    return forward(operation).map(data => {
      const endTime = performance.now()
      const duration = endTime - startTime // Calculate operation execution time.

      // Logs the operation's result and execution time using styled console messages.
      console.log(
        `%c${prefix} %c${operation.operationName || 'Operation'} %cResult:`,
        prefixStyle,
        nameStyle,
        contextStyle,
        JSON.stringify(data, undefined, 2)
      )

      console.log(
        `%c${prefix} %c${operation.operationName || 'Operation'} %cTime: %c${duration}ms`,
        prefixStyle,
        nameStyle,
        contextStyle,
        timeStyle
      )

      return data
    })
  })
}
