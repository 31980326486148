export const ERROR_CODES = {
  USER_NOT_FOUND: 'USER_NOT_FOUND',
  ACCOUNT_WITH_EMAIL_ALREADY_EXISTS: 'ACCOUNT_WITH_EMAIL_ALREADY_EXISTS',
  ACCOUNT_NOT_VERIFIED: 'ACCOUNT_NOT_VERIFIED',
  ACCOUNT_ALREADY_VERIFIED: 'ACCOUNT_ALREADY_VERIFIED',
  INVALID_VERIFICATION_CODE: 'INVALID_VERIFICATION_CODE',
  RESTRICTED: 'PERMISSION_DENIED',
  INVALID_PASSWORD: 'INVALID_PASSWORD',
  INVALID_TOKEN: 'INVALID_TOKEN',
  EXCEEDED_LOGIN_ATTEMPTS: 'EXCEEDED_LOGIN_ATTEMPTS',
  FORBIDDEN: 'FORBIDDEN',
  VALIDATION_ERROR: 'VALIDATION_ERROR',
  DATABASE_ERROR: 'DATABASE_ERROR',
  INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
  UNAUTHORIZED: 'UNAUTHORIZED',
  INVALID_USER_DATA: 'INVALID_USER_DATA',
  UPDATE_PASSWORD_ERROR: 'UPDATE_PASSWORD_ERROR'
} as const

export type ErrorCodes = (typeof ERROR_CODES)[keyof typeof ERROR_CODES]
