import type { EnvCombined } from '~/validation/envLoader'
import { loadEnv } from '~/validation/envLoader'

export const ENV: EnvCombined = loadEnv()

// Common environment variables (available on both server and client)
export const commonEnv = {
  SITE_NAME: ENV.SITE_NAME || ENV.NEXT_PUBLIC_SITE_NAME,
  SITE_DESCRIPTION: ENV.SITE_DESCRIPTION || ENV.NEXT_PUBLIC_SITE_DESCRIPTION,
  FRONTEND_URL: ENV.FRONTEND_URL || ENV.NEXT_PUBLIC_FRONTEND_URL,
  SITE_LANGUAGE: ENV.SITE_LANGUAGE || ENV.NEXT_PUBLIC_SITE_LANGUAGE,
  SITE_TYPE: ENV.SITE_TYPE || ENV.NEXT_PUBLIC_SITE_TYPE,
  SITE_IMAGE_URL: ENV.SITE_IMAGE_URL || ENV.NEXT_PUBLIC_SITE_IMAGE_URL,
  SITE_IMAGE_ALT: ENV.SITE_IMAGE_ALT || ENV.NEXT_PUBLIC_SITE_IMAGE_ALT,
  SITE_AUTHOR_NAME: ENV.SITE_AUTHOR_NAME || ENV.NEXT_PUBLIC_SITE_AUTHOR_NAME,
  SENTRY_DSN: ENV.SENTRY_DSN || ENV.NEXT_PUBLIC_SENTRY_DSN
}

// Server-only environment variables
export const serverEnv = {
  RESEND_API_KEY: ENV.RESEND_API_KEY,
  CONTACT_EMAIL: ENV.CONTACT_EMAIL,
  CONTACT_PHONE: ENV.CONTACT_PHONE,
  CONTACT_ADDRESS: ENV.CONTACT_ADDRESS
}
