import type { ReactNode } from 'react'

import { create } from 'zustand'

export type StatusType = 'info' | 'success' | 'warning' | 'error'

export type Alert = {
  id: number
  status: StatusType
  title: ReactNode
  description?: ReactNode
  buttonLabel?: string
  onButtonClick?: () => void
}

type AlertState = {
  alerts: Alert[]
  showAlert: (alert: Omit<Alert, 'id'>) => void
  hideAlert: (id: number) => void
}

export const useAlertStore = create<AlertState>(set => {
  return {
    alerts: [],
    showAlert: alert => {
      return set(state => {
        return {
          alerts: [...state.alerts, { ...alert, id: Date.now() }]
        }
      })
    },
    hideAlert: id => {
      return set(state => {
        return {
          alerts: state.alerts.filter(alert => {
            return alert.id !== id
          })
        }
      })
    }
  }
})
