// Local

import type { NextTopLoaderProps } from 'nextjs-toploader'

import type { ClientEnvInput } from '~/validation/clientEnv.schema'
import { loadEnv } from '~/validation/envLoader'

import { commonEnv } from '../config/env'

const env = loadEnv()

export const GRAPHQL_ENDPOINT = `${commonEnv.FRONTEND_URL}/api/graphql` ?? ''

export const SITE_NAME = (env as ClientEnvInput).NEXT_PUBLIC_SITE_NAME
export const SITE_DESCRIPTION = (env as ClientEnvInput).NEXT_PUBLIC_SITE_DESCRIPTION
export const SITE_LANGUAGE = (env as ClientEnvInput).NEXT_PUBLIC_SITE_LANGUAGE
export const SITE_TYPE = (env as ClientEnvInput).NEXT_PUBLIC_SITE_TYPE as 'website'
export const SITE_IMAGE_URL = '/assets/images/logo/PNG/duomath.jpeg'
export const SITE_IMAGE_ALT = 'Smart Fridge Inventory System'

export const IS_PRODUCTION = process.env.NODE_ENV === 'production'

export const LOADER_CONFIG: NextTopLoaderProps = {
  color: '#2299DD',
  initialPosition: 0.08,
  crawlSpeed: 200,
  height: 3,
  crawl: true,
  showSpinner: true,
  easing: 'ease',
  speed: 200,
  shadow: '0 0 10px #2299DD,0 0 5px #2299DD',
  template:
    '<div class="bar" role="bar"><div class="peg"></div></div>' +
    '<div class="spinner" role="spinner"><div class="spinner-icon"></div></div>',
  zIndex: 1600,
  showAtBottom: false
} as const

// Miscellaneous

export const IMAGE_PLACEHOLDER = '/assets/images/placeholder/product-image-placeholder.svg'

export const sliderSettings = {
  dots: true,
  slidesToShow: 4,
  slidesToScroll: 4,
  initialSlide: 0,
  infinite: true,
  autoplay: true,
  speed: 1000,
  autoplaySpeed: 4000,
  cssEase: 'linear',
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    }
  ]
}

export const sliderSettingsTestimonials = {
  dots: true,
  slidesToShow: 4,
  slidesToScroll: 4,
  initialSlide: 0,
  infinite: true,
  autoplay: true,
  speed: 1000,
  autoplaySpeed: 4000,
  cssEase: 'linear',
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
}
