/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react-hooks/rules-of-hooks */
import type { ApolloClientOptions, NormalizedCacheObject } from '@apollo/client'
import { ApolloClient } from '@apollo/client'
// import { registerApolloClient } from "@apollo/experimental-nextjs-app-support/rsc";
import {
  ApolloClient as NextSSRApolloClient,
  InMemoryCache as NextSSRInMemoryCache
} from '@apollo/experimental-nextjs-app-support'

import type { ApolloLinkConfig } from '~/types'

import { apolloNextSSRCache, apolloRSCCache } from './apolloCache'
import { createApolloLinks } from './createLinks'

function createClientOptions(config: ApolloLinkConfig): ApolloClientOptions<NormalizedCacheObject> {
  const options: ApolloClientOptions<NormalizedCacheObject> = {
    ssrMode: config.isSSR,
    link: createApolloLinks(config),
    credentials: 'include',
    connectToDevTools: process.env.NODE_ENV === 'development',
    cache: config.isSSR ? apolloNextSSRCache : apolloRSCCache,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'none'
      },
      query: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all'
      }
    }
  }

  return options
}

// For React Server Components
// export const { getClient: RSCClient } = registerApolloClient(() => {
//   const options = createClientOptions(false);
//   return new ApolloClient(options);
// });

// For React Server Components
export function RSCClient(userId = '') {
  const config: ApolloLinkConfig = {
    isSSR: false,
    userId
  }

  const options = createClientOptions(config)

  return new ApolloClient(options)
}

// For Server Side Rendering - and for the use hooks.
export function SSRClient(userId = '') {
  const config: ApolloLinkConfig = {
    isSSR: true,
    userId
  }

  const options = createClientOptions(config)

  return new NextSSRApolloClient({
    ...options,
    cache: new NextSSRInMemoryCache().restore({})
  })
}
