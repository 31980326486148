import type { Operation } from '@apollo/client'
import { ApolloLink, Observable } from '@apollo/client'

import { fetchAccessToken } from '../../actions/fetchAccessToken'

/**
 * Asynchronously sets the authorization header for a given operation.
 *
 * This function fetches an access token using the provided user ID and provider,
 * and sets the 'Authorization' header of the operation if the token is successfully retrieved.
 * If no token is available or an error occurs, it logs the error.
 *
 * @param {Operation} operation - The Apollo operation for which to set the auth header.
 * @param {string} userId - The user's ID.
 * @param {string} provider - The authentication provider.
 * @throws Will throw an error if fetching the access token fails.
 */
const setAuthHeader = async (operation: Operation, userId: string, provider: string) => {
  try {
    const accessToken = await fetchAccessToken(userId, provider)

    console.log('setAuthHeader_accessToken:', accessToken)

    // if (!accessToken) {
    //   console.error('No access token available')
    //   return
    // }

    console.log('Setting auth token:', accessToken)
    operation.setContext({
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })
  } catch (error) {
    console.error('Error setting auth header:', error)
    throw error // Rethrow to be caught by the Observable's error handler
  }
}

/**
 * Creates an ApolloLink that sets the authorization header for each request.
 *
 * This link fetches an access token using the provided user ID and provider,
 * and sets the 'Authorization' header for each operation. Due to the nature of ApolloLink
 * expecting a synchronous function but needing to perform asynchronous operations (like fetching
 * an access token), this function utilizes an async IIFE within an Observable. The async IIFE
 * approach is preferred over using `.then` for promise resolution to keep the code cleaner and
 * more readable. It also allows for the use of `async/await` syntax within the Observable's executor,
 * which is not directly possible due to its synchronous requirement. This pattern ensures that
 * asynchronous token fetching and header setting are handled effectively before proceeding with
 * the operation.
 *
 * Note: Directly using `async/await` without an IIFE within the Observable would not work, as
 * the Observable constructor does not support async functions. The async IIFE is a workaround
 * that enables asynchronous operation while adhering to the Observable pattern required by Apollo.
 *
 * @param {string} userId - The user's ID.
 * @param {string} provider - The authentication provider.
 * @returns {ApolloLink} An ApolloLink that sets the authorization header.
 */
export function createAuthLink(userId: string, provider: string) {
  // console.log('AUTHLINK?????????', userId, provider)
  return new ApolloLink((operation, forward) => {
    console.log('Observable constructor called')

    return new Observable(observer => {
      (async () => {
        try {
          console.log('AuthHeader?????')
          await setAuthHeader(operation, userId, provider)
          const handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer)
          })

          return () => {
            handle.unsubscribe()
          }
        } catch (error) {
          console.error('Error in auth link:', error)
          observer.error(error)
        }
      })()
    })
  })
}
