import { split } from '@apollo/client/link/core'
import { getOperationAST } from 'graphql'

import { httpLink } from './httpLink'
import { sseLink } from './SSELink'

export const routingLink = split(
  ({ query, operationName }) => {
    const definition = getOperationAST(query, operationName)

    return definition?.kind === 'OperationDefinition' && definition.operation === 'subscription'
  },
  sseLink,
  httpLink
)
