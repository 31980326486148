'use client'

import { useMemo } from 'react'

import { ApolloNextAppProvider } from '@apollo/experimental-nextjs-app-support'
import type { User as ClerkUser } from '@clerk/nextjs/server'

import { SSRClient } from './apolloClient'

type ApolloWrapperProps = {
  clerkUser: ClerkUser | null
  initialApolloState: any
  children: React.ReactNode
}

export function ApolloWrapper({ children, clerkUser, initialApolloState }: ApolloWrapperProps) {
  const ssrClient = useMemo(() => {
    const userId = clerkUser?.id || ''

    const client = SSRClient(userId)

    if (initialApolloState) {
      try {
        client.cache.restore(initialApolloState)
      } catch (error) {
        console.error('Error restoring initial Apollo state:', error)
      }
    } else {
      console.warn('Initial Apollo state is undefined or null.')
    }

    return client
  }, [clerkUser, initialApolloState])

  return (
    <ApolloNextAppProvider
      makeClient={() => {
        return ssrClient
      }}
    >
      {children}
    </ApolloNextAppProvider>
  )
}
