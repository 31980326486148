'use client'

import { UserButton, useUser } from '@clerk/nextjs'
import { dark } from '@clerk/themes'
import type { User } from '@prisma/client'
import { Settings } from 'lucide-react'
import { useTheme } from 'next-themes'
import { usePathname } from 'next/navigation'

import { GradientText } from '~/components/GradientText'
import { ENV } from '~/config/env'
import { Link } from '~/UI-Temp/src/UI/index'
import { ToggleThemeMode } from '~/utils/ToggleThemeMode'

import { PERMISSIONS } from '../constants/permissions'
import { ROUTES, getRoutePath } from '../constants/routes'

import { SidebarTrigger } from './ui/sidebar'

type TopbarProps = {
  user: User | null
  permissions: string[]
  capabilities: string[]
}

const renderHeadingContent = (currentPath: string) => {
  const content = <GradientText variant="green-blue">{ENV.NEXT_PUBLIC_SITE_NAME}</GradientText>

  const homeRoute = getRoutePath(ROUTES.HOME)

  if (currentPath === homeRoute) {
    return content
  }

  return <Link href={homeRoute}>{content}</Link>
}

export function Topbar({ user, permissions, capabilities }: TopbarProps) {
  const { isSignedIn } = useUser()
  const currentPathName = usePathname()
  const { theme } = useTheme()
  console.log('TOPBAR_PERMISSIONS', permissions)
  const canAccessSettings = permissions.includes(PERMISSIONS.VIEW_SETTINGS)
  const canAccessNotifications = permissions.includes(PERMISSIONS.VIEW_NOTIFICATIONS)
  const hasProCapabilities = capabilities.includes('pro_features')

  return (
    <header className="sticky top-0 z-50 flex h-20 w-full items-center gap-2 border-b bg-sidebar p-4">
      <div className="flex w-full items-center">
        {/* Left section - fixed width */}
        <div className="flex w-[120px] items-center gap-4">
          {user && <SidebarTrigger className="-ml-1" />}
          <ToggleThemeMode />
          {canAccessSettings && (
            <Link href={getRoutePath(ROUTES.SETTINGS)} className="hover:text-primary">
              <Settings className="size-5" />
              <span className="sr-only">{ROUTES.SETTINGS.title}</span>
            </Link>
          )}
        </div>

        {/* Center section - flex grow with centered content */}
        <div className="grow text-center">
          <h2 className="transition-all duration-500 ease-in-out sm:text-xl md:text-3xl">
            {renderHeadingContent(currentPathName)}
          </h2>
        </div>

        {/* Right section - fixed width */}
        <div className="flex w-[120px] items-center justify-end gap-4">
          {hasProCapabilities && <span className="text-xs font-medium text-primary">PRO</span>}

          {canAccessNotifications && (
            <div className="relative">{/* Add your notification component here */}</div>
          )}

          {!isSignedIn ? (
            <div className="size-12" aria-hidden="true" />
          ) : (
            <UserButton
            afterSignOutUrl="/sign-in"
            appearance={{
              baseTheme: theme === 'dark' ? dark : undefined,
              elements: {
                avatarBox: 'size-8'
              }
            }}
              userProfileMode="navigation"
              userProfileUrl={`/profile/${user?.id}/settings`}
          />
            
          )}
        </div>
      </div>
    </header>
  )
}
