'use client'

import type { User } from '@prisma/client'
import { ChevronRight } from 'lucide-react'
import { usePathname } from 'next/navigation'

import type { Route } from '../constants'
import { ROUTES, getRoutePath } from '../constants'
import { getRouteIcon } from '../constants/routeIcons'
import { Collapsible, CollapsibleContent, CollapsibleTrigger, Link } from '../UI-Temp/src/UI'

import { PERMISSIONS } from '../constants/permissions'
import {
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem
} from './ui/sidebar'

type NavigationLinksProps = {
  user: User | null
  permissions?: string[]
  capabilities?: string[]
}

export function NavigationLinks({
  user,
  permissions = [],
  capabilities = []
}: NavigationLinksProps) {
  const pathname = usePathname()
  const isSuperAdmin = permissions.includes(PERMISSIONS.SUPER_ADMIN)

  console.log('Current permissions:', permissions, 'Is Super Admin:', isSuperAdmin)

  const filteredRoutes = Object.values(ROUTES).filter(route => {
    if (isSuperAdmin) return true

    if (route.path === getRoutePath(ROUTES.NOT_FOUND)) {
      return false
    }

    if (!route.permissions?.length) {
      return true
    }

    return route.permissions.some(p => permissions.includes(p))
  })

  console.log('Filtered routes:', filteredRoutes)

  const renderRouteIcon = (route: Route) => {
    const icon = getRouteIcon(route.path)

    return icon || null
  }

  return (
    <>
      {filteredRoutes.map(route => {
        const href =
          route.path === ROUTES.PROFILE.path && user
            ? getRoutePath(route, { userId: user.id })
            : getRoutePath(route)

        const isActive = pathname === href

        if (route.subRoutes && route.subRoutes.length > 0) {
          return (
            <Collapsible key={route.title} className="group/collapsible">
              <SidebarMenuItem className="py-1">
                <CollapsibleTrigger asChild>
                  <SidebarMenuButton tooltip={route.title} className="text-base">
                    {renderRouteIcon(route)}
                    <span>{route.title}</span>
                    <ChevronRight className="ml-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90" />
                  </SidebarMenuButton>
                </CollapsibleTrigger>
                <CollapsibleContent>
                  <SidebarMenuSub>
                    {route.subRoutes.map(subRoute => {
                      return (
                        <SidebarMenuSubItem key={subRoute.title}>
                          <SidebarMenuSubButton
                            asChild
                            isActive={pathname === getRoutePath(subRoute)}
                            className="text-base"
                          >
                            <Link href={getRoutePath(subRoute)}>
                              <span>{subRoute.title}</span>
                            </Link>
                          </SidebarMenuSubButton>
                        </SidebarMenuSubItem>
                      )
                    })}
                  </SidebarMenuSub>
                </CollapsibleContent>
              </SidebarMenuItem>
            </Collapsible>
          )
        }

        return (
          <SidebarMenuItem key={route.title} className="py-1">
            <SidebarMenuButton
              asChild
              tooltip={route.title}
              isActive={isActive}
              className="text-base"
            >
              <Link href={href}>
                {renderRouteIcon(route)}
                <span>{route.title}</span>
              </Link>
            </SidebarMenuButton>
          </SidebarMenuItem>
        )
      })}
    </>
  )
}
