import type { ReactNode } from 'react'

import { ChartPieIcon, Cog8ToothIcon, HomeIcon, UserIcon } from '@heroicons/react/24/outline'
import { MessageSquareTextIcon, UsersIcon } from 'lucide-react'

import type { Route } from './routes'
import { ROUTES } from './routes'

type RouteWithIcon = Pick<Route, 'path'> & {
  icon: ReactNode
}

export const ROUTE_ICONS: Record<Route['path'], RouteWithIcon> = {
  [ROUTES.HOME.path]: {
    path: ROUTES.HOME.path,
    icon: <HomeIcon className="size-6 text-gray-400" />
  },
  [ROUTES.PROFILE.path]: {
    path: ROUTES.PROFILE.path,
    icon: <UserIcon className="size-6 text-gray-400" />
  },
  [ROUTES.MESSAGES.path]: {
    path: ROUTES.MESSAGES.path,
    icon: <MessageSquareTextIcon className="size-6 text-gray-400" />
  },
  [ROUTES.COLLABS.path]: {
    path: ROUTES.COLLABS.path,
    icon: <UsersIcon className="size-6 text-gray-400" />
  },
  [ROUTES.INSIGHTS.path]: {
    path: ROUTES.INSIGHTS.path,
    icon: <ChartPieIcon className="size-6 text-gray-400" />
  },
  [ROUTES.SETTINGS.path]: {
    path: ROUTES.SETTINGS.path,
    icon: <Cog8ToothIcon className="size-6 text-gray-400" />
  },
  [ROUTES.NOT_FOUND.path]: {
    path: ROUTES.NOT_FOUND.path,
    icon: <HomeIcon className="size-6 text-gray-400" />
  }
}

export const getRouteIcon = (path: Route['path']): ReactNode | undefined => {
  return ROUTE_ICONS[path]?.icon
}
