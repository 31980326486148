'use client'

import { AnimatePresence, motion } from 'framer-motion'

import type { Alert } from '~/store/alertStore'
import { useAlertStore } from '~/store/alertStore'
import { StickyAlert } from '~/UI-Temp/src/UI/StickyAlert/StickyAlert'

/**
 * AlertContainer listens to the alert store and renders active alerts.
 *
 * @returns {JSX.Element | null} The AlertContainer component or null if no alerts.
 */
export const AlertContainer = (): JSX.Element | null => {
  const alerts = useAlertStore(state => {
    return state.alerts
  })

  const hideAlert = useAlertStore(state => {
    return state.hideAlert
  })

  if (alerts.length === 0) {
    return null
  }

  return (
    <motion.div
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      className="fixed inset-x-0 top-0 z-50 w-full px-8 pt-4"
    >
      <AnimatePresence mode="popLayout">
        {alerts.map((alert: Alert) => {
          return (
            <motion.div
              key={alert.id}
              layout
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, x: 100 }}
            >
              <StickyAlert
                status={alert.status}
                title={alert.title}
                description={alert.description}
                onClose={() => {
                  return hideAlert(alert.id)
                }}
                buttonLabel={alert.buttonLabel}
                onButtonClick={alert.onButtonClick}
              />
            </motion.div>
          )
        })}
      </AnimatePresence>
    </motion.div>
  )
}
