import type { ReactNode } from 'react'

import { twMerge } from 'tailwind-merge'

type GradientDirection = 'to-r' | 'to-l' | 'to-t' | 'to-b' | 'to-tr' | 'to-tl' | 'to-br' | 'to-bl'

type GradientVariant =
  | 'purple-blue'
  | 'red-yellow'
  | 'green-blue'
  | 'pink-orange'
  | 'indigo-purple'
  | 'orange-red'
  | 'blue-green'
  | 'teal-pink'
  | 'yellow-green'
  | 'cyan-indigo'
  | 'blue-purple'

type GradientTextProps = {
  children: ReactNode
  variant?: GradientVariant
  direction?: GradientDirection
  className?: string
}

const gradientStyles: Record<GradientVariant, string> = {
  'purple-blue': 'from-purple-400 to-blue-500',
  'red-yellow': 'from-red-500 to-yellow-300',
  'green-blue': 'from-green-400 to-blue-600',
  'pink-orange': 'from-pink-500 to-orange-400',
  'indigo-purple': 'from-indigo-500 to-purple-600',
  'orange-red': 'from-orange-400 to-red-500',
  'blue-green': 'from-blue-400 to-green-500',
  'teal-pink': 'from-teal-400 to-pink-500',
  'yellow-green': 'from-yellow-400 to-green-500',
  'cyan-indigo': 'from-cyan-400 to-indigo-500',
  'blue-purple': 'from-blue-500 to-purple-600' // Added new gradient style
}

const directionClasses: Record<GradientDirection, string> = {
  'to-r': 'bg-gradient-to-r',
  'to-l': 'bg-gradient-to-l',
  'to-t': 'bg-gradient-to-t',
  'to-b': 'bg-gradient-to-b',
  'to-tr': 'bg-gradient-to-tr',
  'to-tl': 'bg-gradient-to-tl',
  'to-br': 'bg-gradient-to-br',
  'to-bl': 'bg-gradient-to-bl'
}

export const GradientText: React.FC<GradientTextProps> = ({
  children,
  variant = 'purple-blue',
  direction = 'to-r',
  className = ''
}) => {
  const gradientClass = gradientStyles[variant]
  const directionClass = directionClasses[direction]

  if (!gradientClass) {
    console.warn(`Gradient variant "${variant}" does not exist. Falling back to "purple-blue".`)
  }

  if (!directionClass) {
    console.warn(`Gradient direction "${direction}" does not exist. Falling back to "to-r".`)
  }

  const classes = twMerge(
    `${directionClass} ${gradientClass} bg-clip-text text-transparent`,
    className
  )

  return <span className={classes}>{children}</span>
}
