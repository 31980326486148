'use client'

import type { NormalizedCacheObject } from '@apollo/client'
import type { User as ClerkUser } from '@clerk/nextjs/server'
import type { User as PrismaUser, Room } from '@prisma/client'
import dynamic from 'next/dynamic'

const ClientProviders = dynamic(
  () => {
    return import('~/providers/ClientProviders')
  },
  { ssr: false }
)

type ClientProvidersWrapperProps = {
  children: React.ReactNode
  prismaUser: PrismaUser | null
  clerkUser: ClerkUser | null
  permissions: string[]
  capabilities: string[]
  initialApolloState: NormalizedCacheObject
  room: Room | null
}

export const ClientProvidersWrapper = ({
  children,
  prismaUser,
  clerkUser,
  initialApolloState,
  room
}: ClientProvidersWrapperProps) => {
  return (
    <ClientProviders
      prismaUser={prismaUser}
      clerkUser={clerkUser}
      initialApolloState={initialApolloState}
      room={room}
    >
      {children}
    </ClientProviders>
  )
}
