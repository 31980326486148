import { ApolloLink, Observable, type FetchResult, type Operation } from '@apollo/client/core'
import { print } from 'graphql'

import { GRAPHQL_ENDPOINT } from '~/constants'

type SSELinkOptions = EventSourceInit & { uri: string }

class SSELink extends ApolloLink {
  public constructor(private readonly options: SSELinkOptions) {
    super()
  }

  public request(operation: Operation): Observable<FetchResult> {
    const url = new URL(this.options.uri)
    url.searchParams.append('query', print(operation.query))

    if (operation.operationName) {
      url.searchParams.append('operationName', operation.operationName)
    }

    if (operation.variables) {
      url.searchParams.append('variables', JSON.stringify(operation.variables))
    }

    if (operation.extensions) {
      url.searchParams.append('extensions', JSON.stringify(operation.extensions))
    }

    return new Observable(sink => {
      const eventsource = new EventSource(url.toString(), this.options)
      eventsource.onmessage = function (event) {
        const data = JSON.parse(event.data)
        sink.next(data)

        if (eventsource.readyState === 2) {
          sink.complete()
        }
      }

      eventsource.onerror = function (error) {
        console.log('SSELink____onerror', error)
        sink.error(error)
      }

      return () => {
        return eventsource.close()
      }
    })
  }
}

export const sseLink = new SSELink({
  uri: GRAPHQL_ENDPOINT
})
