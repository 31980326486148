import type { ApolloError } from '@apollo/client'
import { RetryLink } from '@apollo/client/link/retry'

// We don't necessarily want to retry every operation. This should be on an opt in basis.
// We might be interested in retrying certain mutations for example.

// Always write optimistic responses for mutations, this is great for UX reasons so that the UI will appear snappy, but also especially if we are going offline first

// Source: Going offline first with GraphQL - Kadi Kraman - https://youtu.be/i4Cfq7hQiws?t=521

export const retryLink = new RetryLink({
  attempts: {
    max: 2,
    retryIf: (error: ApolloError, _operation) => {
      if (error.message === 'Network request failed') {
        if (_operation.operationName === 'addCountry') {
          return true
        }
      }

      return Boolean(error)
    }
  },
  delay: (count, operation, error) => {
    return count * 1000 * Math.random()
  }
})
