import { PERMISSIONS } from './permissions'
import { SITE_CONFIG } from './siteData'

export type RoutePathname = string

export type Route = {
  path: string
  generatePath: (params?: Record<string, string | number>) => RoutePathname
  title: string
  titleWithSiteName: string
  description: string
  isDisabled?: boolean
  permissions?: string[]
  capabilities?: string[]
  subRoutes?: Route[]
}

const RouteKeys = {
  HOME: 'HOME',
  PROFILE: 'PROFILE',
  MESSAGES: 'MESSAGES',
  COLLABS: 'COLLABS',
  INSIGHTS: 'INSIGHTS',
  SETTINGS: 'SETTINGS',
  NOT_FOUND: 'NOT_FOUND'
} as const

type Routes = {
  [key in (typeof RouteKeys)[keyof typeof RouteKeys]]: Route
}

const AuthRoutes = {
  SIGNIN: 'SIGNIN',
  SIGNUP: 'SIGNUP',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  RESET_PASSWORD: 'RESET_PASSWORD'
} as const

type TAuthRoutes = {
  [key in (typeof AuthRoutes)[keyof typeof AuthRoutes]]: Route
}

export const ROUTES: Routes = {
  HOME: {
    path: '/',
    generatePath: () => {
      return '/'
    },
    title: 'Home',
    titleWithSiteName: `Home | ${SITE_CONFIG.siteName}`,
    description: 'This is the description for the home route',
    permissions: [PERMISSIONS.VIEW_HOME]
  },
  PROFILE: {
    path: '/profile/:userId',
    generatePath: params => {
      return `/profile/${params?.userId || ''}`
    },
    title: 'Profile',
    titleWithSiteName: `Profile | ${SITE_CONFIG.siteName}`,
    description: 'Profile',
    permissions: [PERMISSIONS.VIEW_PROFILE]
  },
  MESSAGES: {
    path: '/chat',
    generatePath: () => {
      return '/chat'
    },
    title: 'Messages',
    titleWithSiteName: `Messages | ${SITE_CONFIG.siteName}`,
    description: 'Messages',
    permissions: [PERMISSIONS.VIEW_MESSAGES]
  },
  COLLABS: {
    path: '/collaborations',
    generatePath: () => {
      return '/collaborations'
    },
    title: 'Collaborations',
    titleWithSiteName: `Collaborations | ${SITE_CONFIG.siteName}`,
    description: 'Collaborations',
    permissions: [PERMISSIONS.VIEW_COLLABORATIONS]
  },
  INSIGHTS: {
    path: '/insights',
    generatePath: () => {
      return '/insights'
    },
    title: 'Insights',
    titleWithSiteName: `Insights | ${SITE_CONFIG.siteName}`,
    description: 'Insights',
    permissions: [PERMISSIONS.VIEW_INSIGHTS]
  },
  SETTINGS: {
    path: '/settings',
    generatePath: () => {
      return '/settings'
    },
    title: 'Settings',
    titleWithSiteName: `Settings | ${SITE_CONFIG.siteName}`,
    description: 'Settings',
    permissions: [PERMISSIONS.VIEW_SETTINGS],
    subRoutes: [
      {
        path: '/settings/profile',
        generatePath: () => {
          return '/settings/profile'
        },
        title: 'Profile Settings',
        titleWithSiteName: `Profile Settings | ${SITE_CONFIG.siteName}`,
        description: 'Profile Settings',
        permissions: [PERMISSIONS.VIEW_SETTINGS]
      },
      {
        path: '/settings/account',
        generatePath: () => {
          return '/settings/account'
        },
        title: 'Account Settings',
        titleWithSiteName: `Account Settings | ${SITE_CONFIG.siteName}`,
        description: 'Account Settings',
        permissions: [PERMISSIONS.VIEW_SETTINGS]
      }
    ]
  },
  NOT_FOUND: {
    path: '/404',
    generatePath: () => {
      return '/404'
    },
    title: '404',
    titleWithSiteName: `404 | ${SITE_CONFIG.siteName}`,
    description: 'Page not found'
  }
} as const

export const AUTH_ROUTES: TAuthRoutes = {
  SIGNIN: {
    path: '/sign-in',
    generatePath: () => {
      return '/sign-in'
    },
    title: 'Sign In',
    titleWithSiteName: `Sign In | ${SITE_CONFIG.siteName}`,
    description: 'Sign in to your account'
  },
  SIGNUP: {
    path: '/sign-up',
    generatePath: () => {
      return '/sign-up'
    },
    title: 'Sign Up',
    titleWithSiteName: `Sign Up | ${SITE_CONFIG.siteName}`,
    description: 'Sign up for an account'
  },
  FORGOT_PASSWORD: {
    path: '/forgot-password',
    generatePath: () => {
      return '/forgot-password'
    },
    title: 'Forgot Password',
    titleWithSiteName: `Forgot Password | ${SITE_CONFIG.siteName}`,
    description: 'Forgot your password?'
  },
  RESET_PASSWORD: {
    path: '/reset-password',
    generatePath: () => {
      return '/reset-password'
    },
    title: 'Reset Password',
    titleWithSiteName: `Reset Password | ${SITE_CONFIG.siteName}`,
    description: 'Reset your password'
  }
} as const

export const getRoutePath = (route: Route, params?: Record<string, string | number>): string => {
  return route.generatePath(params)
}

export const ROUTE_DATA = Object.values(ROUTES).filter(route => {
  return route.title !== '404'
})

export const AUTH_ROUTE_DATA = Object.values(AUTH_ROUTES)
