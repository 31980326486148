import { z } from 'zod'

export const clientEnvSchema = z.object({
  NEXT_PUBLIC_FRONTEND_URL: z.string().url(),
  NEXT_PUBLIC_SITE_NAME: z.string(),
  NEXT_PUBLIC_SITE_DESCRIPTION: z.string(),
  NEXT_PUBLIC_SITE_AUTHOR_NAME: z.string(),
  NEXT_PUBLIC_SITE_LANGUAGE: z.string(),
  NEXT_PUBLIC_SITE_TYPE: z.string(),
  NEXT_PUBLIC_SITE_IMAGE_URL: z.string(),
  NEXT_PUBLIC_SITE_IMAGE_ALT: z.string(),
  NEXT_PUBLIC_LOCALFORAGE_APP_NAME: z.string(),
  NEXT_PUBLIC_LOCALFORAGE_STORE_NAME: z.string(),
  NEXT_PUBLIC_APOLLO_CACHE_KEY: z.string(),
  NEXT_PUBLIC_SENTRY_DSN: z.string(),
  NEXT_PUBLIC_TRIGGER_PUBLIC_API_KEY: z.string(),
  NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY: z.string(),
  NEXT_PUBLIC_STRIPE_PUBLIC_KEY: z.string(),
  NEXT_PUBLIC_HOTJAR_ID: z.string(),
  NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME: z.string(),
  // Pusher
  NEXT_PUBLIC_PUSHER_KEY: z.string(),
  NEXT_PUBLIC_PUSHER_CLUSTER: z.string(),
  // PostHog
  NEXT_PUBLIC_POSTHOG_KEY: z.string(),
  NEXT_PUBLIC_POSTHOG_HOST: z.string()
})

export type ClientEnvInput = z.TypeOf<typeof clientEnvSchema>

/**
 * Defines a mapping of client-side environment variables.
 *
 * Due to the way Webpack and similar bundlers (used in frameworks like Next.js) handle `process.env`,
 * the entire `process.env` object is not made available in the client-side JavaScript bundle. Instead,
 * only the variables explicitly referenced in the code are included. This behavior ensures that sensitive
 * server-side environment variables are not accidentally exposed to the client-side.
 *
 * As a result, to use environment variables on the client-side, we need to explicitly define each variable
 * we intend to use. This object maps each required client-side environment variable to its corresponding
 * value from `process.env`, making them accessible in the client-side code.
 *
 * Note: Only variables prefixed with `NEXT_PUBLIC_` are exposed to the client-side in Next.js applications,
 * as a convention to prevent unintentional exposure of sensitive information.
 *
 * Example Usage:
 * ```typescript
 * console.log(clientEnvVariables.NEXT_PUBLIC_FRONTEND_URL); // Accessing a client-side environment variable
 * ```
 *
 * @type {{ [key: string]: string | undefined }}
 */
export const clientEnvVariables = {
  NEXT_PUBLIC_FRONTEND_URL: process.env.NEXT_PUBLIC_FRONTEND_URL,
  NEXT_PUBLIC_BACKEND_URL: process.env.NEXT_PUBLIC_BACKEND_URL,
  NEXT_PUBLIC_SITE_NAME: process.env.NEXT_PUBLIC_SITE_NAME,
  NEXT_PUBLIC_SITE_DESCRIPTION: process.env.NEXT_PUBLIC_SITE_DESCRIPTION,
  NEXT_PUBLIC_SITE_AUTHOR_NAME: process.env.NEXT_PUBLIC_SITE_AUTHOR_NAME,
  NEXT_PUBLIC_SITE_LANGUAGE: process.env.NEXT_PUBLIC_SITE_LANGUAGE,
  NEXT_PUBLIC_SITE_IMAGE_URL: process.env.NEXT_PUBLIC_SITE_IMAGE_URL,
  NEXT_PUBLIC_SITE_IMAGE_ALT: process.env.NEXT_PUBLIC_SITE_IMAGE_ALT,
  NEXT_PUBLIC_SITE_TYPE: process.env.NEXT_PUBLIC_SITE_TYPE,
  NEXT_PUBLIC_LOCALFORAGE_APP_NAME: process.env.NEXT_PUBLIC_LOCALFORAGE_APP_NAME,
  NEXT_PUBLIC_LOCALFORAGE_STORE_NAME: process.env.NEXT_PUBLIC_LOCALFORAGE_STORE_NAME,
  NEXT_PUBLIC_APOLLO_CACHE_KEY: process.env.NEXT_PUBLIC_APOLLO_CACHE_KEY,
  NEXT_PUBLIC_SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
  NEXT_PUBLIC_TRIGGER_PUBLIC_API_KEY: process.env.NEXT_PUBLIC_TRIGGER_PUBLIC_API_KEY,
  NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY: process.env.NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY,
  NEXT_PUBLIC_STRIPE_PUBLIC_KEY: process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY,
  NEXT_PUBLIC_HOTJAR_ID: process.env.NEXT_PUBLIC_HOTJAR_ID,
  NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME: process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME,
  // Pusher
  NEXT_PUBLIC_PUSHER_KEY: process.env.NEXT_PUBLIC_PUSHER_KEY,
  NEXT_PUBLIC_PUSHER_CLUSTER: process.env.NEXT_PUBLIC_PUSHER_CLUSTER,
  // PostHog
  NEXT_PUBLIC_POSTHOG_KEY: process.env.NEXT_PUBLIC_POSTHOG_KEY,
  NEXT_PUBLIC_POSTHOG_HOST: process.env.NEXT_PUBLIC_POSTHOG_HOST
}
