export const PERMISSIONS = {
  VIEW_NOTIFICATIONS: 'view_notifications',
  VIEW_SETTINGS: 'view_settings',
  EDIT_SETTINGS: 'edit_settings',
  VIEW_HEADER_EXTRAS: 'view_header_extras',
  VIEW_HOME: 'view_home',
  VIEW_PROFILE: 'view_profile',
  VIEW_MESSAGES: 'view_messages',
  VIEW_COLLABORATIONS: 'view_collaborations',
  VIEW_INSIGHTS: 'view_insights',
  INVITE_STAFF: 'invite_staff',
  DELETE_STAFF: 'delete_staff',
  DELETE_ACCOUNT: 'delete_account',
  CREATE_OFFER: 'create_offer',
  DELETE_OFFER: 'delete_offer',
  SUPER_ADMIN: 'superAdmin:totalAccess'
} as const

export type Permission = (typeof PERMISSIONS)[keyof typeof PERMISSIONS]

export const DEFAULT_USER_PERMISSIONS = [
  PERMISSIONS.VIEW_HOME,
  PERMISSIONS.VIEW_PROFILE,
  PERMISSIONS.VIEW_MESSAGES,
  PERMISSIONS.VIEW_COLLABORATIONS,
  PERMISSIONS.VIEW_INSIGHTS,
  PERMISSIONS.VIEW_SETTINGS
] as const

export const DEFAULT_USER_PERMISSIONS_WITH_SUPER_ADMIN = [
  ...DEFAULT_USER_PERMISSIONS,
  PERMISSIONS.SUPER_ADMIN
] as const

export const DEFAULT_USER_CAPABILITIES = [PERMISSIONS.SUPER_ADMIN] as const
