import { z } from 'zod'

export const serverEnvSchema = z.object({
  // Local
  FRONTEND_URL: z.string().url(),

  // Site Config
  SITE_NAME: z.string(),
  SITE_DESCRIPTION: z.string(),
  SITE_LANGUAGE: z.string(),
  SITE_TYPE: z.string(),
  SITE_IMAGE_URL: z.string().url(),
  SITE_IMAGE_ALT: z.string(),
  SITE_AUTHOR_NAME: z.string(),
  // SENTRY_AUTH_TOKEN: z.string(),
  // Contact
  CONTACT_EMAIL: z.string().email(),
  CONTACT_PHONE: z.string().optional(),
  CONTACT_ADDRESS: z.string().optional(),
  // Resend
  RESEND_API_KEY: z.string(),
  // Sendgrid
  SENDGRID_API_KEY: z.string(),
  SENDGRID_EMAIL_SENDER: z.string(),
  // Google
  GOOGLE_CLIENT_ID: z.string(),
  GOOGLE_CLIENT_SECRET: z.string(),
  // Youtube
  YOUTUBE_API_KEY: z.string(),
  YOUTUBE_CLIENT_ID: z.string(),
  YOUTUBE_CLIENT_SECRET: z.string(),
  // Tiktok
  TIKTOK_CLIENT_KEY: z.string(),
  TIKTOK_CLIENT_SECRET: z.string(),
  // Docusign
  // DOCUSIGN_BASE_URL: z.string(),
  // DOCUSIGN_ACCOUNT_ID: z.string(),
  // DOCUSIGN_API_KEY: z.string(),
  // DOCUSIGN_API_SECRET: z.string(),
  // DOCUSIGN_API_USERNAME: z.string(),
  // DOCUSIGN_API_PASSWORD: z.string(),
  // Github
  // GITHUB_CLIENT_ID: z.string(),
  // GITHUB_CLIENT_SECRET: z.string(),
  // Cloudinary
  CLOUDINARY_CLOUD_NAME: z.string(),
  CLOUDINARY_API_KEY: z.string(),
  CLOUDINARY_API_SECRET: z.string(),
  // Clerk
  CLERK_SECRET_KEY: z.string(),
  CLERK_WEBHOOK_SECRET: z.string(),
  // Sentry
  SENTRY_DSN: z.string(),
  SENTRY_AUTH_TOKEN: z.string(),
  // Sendgrid
  // SENDGRID_API_KEY: z.string(),
  // SENDGRID_EMAIL_SENDER: z.string(),
  // Stripe
  STRIPE_SECRET_KEY: z.string(),
  // Meilisearch
  MEILISEARCH_HOST: z.string(),
  MEILISEARCH_API_KEY: z.string(),
  // OpenAI
  OPENAI_API_KEY: z.string(),
  // Trigger
  TRIGGER_API_KEY: z.string(),
  TRIGGER_API_URL: z.string(),
  // Cloudflare
  CLOUDFLARE_WORKER_URL: z.string(),
  // Upstash
  UPSTASH_REDIS_URL: z.string(),
  // TaxJar
  TAXJAR_API_KEY: z.string(),
  TAXJAR_API_URL: z.string(),
  // Pusher
  PUSHER_APP_ID: z.string(),
  PUSHER_SECRET: z.string(),
  PUSHER_KEY: z.string(),
  PUSHER_CLUSTER: z.string()
})

export type ServerEnvInput = z.TypeOf<typeof serverEnvSchema>
