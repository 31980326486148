export { ERROR_CODES } from './error'
export type { ErrorCodes } from './error'
export {
  GRAPHQL_ENDPOINT,
  IMAGE_PLACEHOLDER,
  IS_PRODUCTION,
  SITE_DESCRIPTION,
  SITE_IMAGE_ALT,
  SITE_IMAGE_URL,
  SITE_LANGUAGE,
  SITE_NAME,
  SITE_TYPE,
  sliderSettings,
  sliderSettingsTestimonials
} from './main'
export * from './routes'
export * from './siteData'
